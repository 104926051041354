import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
	{
    path: '/material/:collect/:filename',
    name: 'Material',
    component: () => import(/* webpackChunkName: "about" */ '../views/Material.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(function (to, from) {
	// 页面title
	// document.title = '盛光传媒集团'
	document.title = ' '
	// 百度统计
	// const pathname = window.location.pathname + '#' + to.fullPath;
	const { origin, pathname } = window.location;
	window._hmt && window._hmt.push(['_trackPageview', `${origin}${pathname}`]);

  if(to.path !== from.path ){
    window.scroll(0, 0)
  }
})

export default router
